import createBehavior from '../functions/createBehavior';

const SPACEBAR_KEY_CODE = [0, 32];
const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;
const OPEN_CLASS = 's-open';

const select = createBehavior(
  'select',
  {
    setSelectedListItem(e, options) {
      const selectedTextToAppend = document.createTextNode(e.target.innerText);
      const opts = this.select.options;
      const optsArray = Array.prototype.slice.call(opts);
      const { index } = e.target.dataset;
      this.dropdownSelectedNode.innerHTML = null;
      this.dropdownSelectedNode.appendChild(selectedTextToAppend);

      optsArray.forEach((opt, optIndex) => {
        if (optIndex === parseInt(index, 10)) {
          this.select.selectedIndex = optIndex;
          if (options.triggerEvent) {
            this.select.dispatchEvent(new Event('change'));
          }
        }
      });
    },

    closeList() {
      this.node.classList.remove(OPEN_CLASS);
      this.list.classList.remove(OPEN_CLASS);
      this.dropdownArrow.classList.remove(OPEN_CLASS);
      this.listContainer.setAttribute('aria-expanded', false);
      this.listItems.forEach(item => item.setAttribute('tabindex', -1));
    },

    toggleListVisibility(e) {
      const openDropDown =
        SPACEBAR_KEY_CODE.includes(e.keyCode) || e.keyCode === ENTER_KEY_CODE;
      if (e.keyCode === ESCAPE_KEY_CODE) {
        this.closeList();
      }
      if (e.type === 'click' || openDropDown) {
        e.preventDefault();
        this.node.classList.toggle(OPEN_CLASS);
        this.list.classList.toggle(OPEN_CLASS);
        this.dropdownArrow.classList.toggle(OPEN_CLASS);
        this.listContainer.setAttribute(
          'aria-expanded',
          this.list.classList.contains(OPEN_CLASS)
        );
        this.listItems.forEach(item => item.setAttribute('tabindex', 0));
      }
      if (e.keyCode === DOWN_ARROW_KEY_CODE) {
        this.focusNextListItem(DOWN_ARROW_KEY_CODE);
      }
      if (e.keyCode === UP_ARROW_KEY_CODE) {
        this.focusNextListItem(UP_ARROW_KEY_CODE);
      }
    },
    focusNextListItem(direction) {
      const activeElem = document.activeElement;
      if (activeElem.className === 'm-select__selected') {
        this.list
          .querySelector(`[data-index="${this.listItemsArray[0]}"]`)
          .focus();
      } else {
        const currentActiveElementIndex = this.listItemsArray.indexOf(
          activeElem.dataset.index
        );
        if (direction === DOWN_ARROW_KEY_CODE) {
          const currentActiveElementIsNotLastItem =
            currentActiveElementIndex < this.listItemsArray.length - 1;
          if (currentActiveElementIsNotLastItem) {
            const nextListItemId = this.listItemsArray[
              currentActiveElementIndex + 1
            ];
            this.list.querySelector(`[data-index="${nextListItemId}"]`).focus();
          }
        } else if (direction === UP_ARROW_KEY_CODE) {
          const currentActiveElementIsNotFirstItem =
            currentActiveElementIndex > 0;
          if (currentActiveElementIsNotFirstItem) {
            const nextListItemId = this.listItemsArray[
              currentActiveElementIndex - 1
            ];
            this.list.querySelector(`[data-index="${nextListItemId}"]`).focus();
          }
        }
      }
    },
    buildSelect() {
      const selectInput = this.node.querySelector('.m-select__input');
      if(selectInput) return
      const div = document.createElement('div');
      const btn = document.createElement('button');
      const icon = document.createElement('span');
      const container = document.createElement('div');
      const list = document.createElement('ul');
      const options = this.select.querySelectorAll('option');

      div.classList.add('m-select__input');

      btn.setAttribute('role', 'button');
      btn.setAttribute('tabindex', 0);
      btn.classList.add('m-select__selected');
      btn.innerHTML = options[0].innerHTML;

      icon.classList.add('m-select__icon');
      icon.innerHTML =
        '<svg class="icon--chevron--down"><use xlink:href="#icon--chevron--down"></use></svg>';

      container.setAttribute('role', 'list');
      container.setAttribute('aria-expanded', 'false');
      container.classList.add('m-select__list-container');

      list.classList.add('m-select__list');

      options.forEach((option, index) => {
        const text = option.innerHTML;
        const item = document.createElement('li');

        item.setAttribute('tabindex', -1);
        item.setAttribute('data-index', `${index}`);
        item.setAttribute('value', option.value);
        if (option.dataset.lang) {
          item.setAttribute('data-lang', option.dataset.lang);
        }
        item.classList.add('m-select__list-item');
        item.innerHTML = text;

        if (option.disabled) {
          item.style.display = 'none';
        }

        if (option.dataset.location) {
          item.setAttribute('data-location', option.dataset.location);
        }

        list.appendChild(item);
      });

      div.appendChild(btn);
      div.appendChild(icon);
      container.appendChild(list);
      div.appendChild(container);

      this.node.appendChild(div);
    },

    triggerList(event) {
      if (event.target.closest('.m-select')) {
        return;
      }
      this.closeList();
    },

    setSelected(selectNode) {
      this.dropdownSelectedNode.innerHTML = null;
      this.dropdownSelectedNode.innerHTML = selectNode.innerText;
    },

    handleChange(e) {
        this.setSelected(this.select[e.target.selectedIndex]);
    },

    handleClick(e) {
        this.setSelectedListItem(e, { triggerEvent: true });
        this.closeList();
    },

    handleKeydown(e) {
        switch (e.keyCode) {
            case ENTER_KEY_CODE:
              this.setSelectedListItem(e, { triggerEvent: true });
              this.closeList();
              return;

            case DOWN_ARROW_KEY_CODE:
              this.focusNextListItem(DOWN_ARROW_KEY_CODE);
              return;

            case UP_ARROW_KEY_CODE:
              this.focusNextListItem(UP_ARROW_KEY_CODE);
              return;

            case ESCAPE_KEY_CODE:
              this.closeList();
              break;
            default:
          }
    }
  },

  {
    init() {
      this.select = this.node.querySelector('select');

      if (this.select) {
        this.buildSelect();

        this.btn = this.node.querySelector('.m-select__selected');
        this.list = this.node.querySelector('.m-select__list');
        this.listContainer = this.node.querySelector(
          '.m-select__list-container'
        );
        this.dropdownArrow = this.node.querySelector('.m-select__icon');
        this.listItems = this.node.querySelectorAll('.m-select__list-item');
        this.dropdownSelectedNode = this.node.querySelector(
          '.m-select__selected'
        );
        this.listItemsArray = [];

        this.dropdownSelectedNode.addEventListener('click', this.toggleListVisibility);
        this.dropdownSelectedNode.addEventListener('keydown', this.toggleListVisibility);

        this.select.addEventListener('change', this.handleChange);

        this.listItems.forEach(item =>
          this.listItemsArray.push(item.dataset.index)
        );
        this.listItems.forEach(item => {
          item.addEventListener('click', this.handleClick);

          item.addEventListener('keydown', this.handleKeydown);
        });

        // // set initial selected option without triggering change event
        const selectIndex = this.select.selectedIndex;
        const simulateEvent = {
          target: this.listItems[selectIndex],
        };
        this.setSelectedListItem(simulateEvent, { triggerEvent: false });
        this.setSelected(this.select[selectIndex]);

        document.addEventListener('click', this.triggerList, false);
      } else {
        console.warn('No select element present');
      }
    },
    destroy() {
      document.removeEventListener('click', this.triggerList, false);
      this.dropdownSelectedNode.removeEventListener('click', this.toggleListVisibility);
      this.dropdownSelectedNode.removeEventListener('keydown', this.toggleListVisibility);
      this.select.removeEventListener('change', this.handleChange);
      document.removeEventListener('click', this.triggerList, false);
      this.listItems.forEach(item => {
        item.removeEventListener('click', this.handleClick);

        item.removeEventListener('keydown', this.handleKeydown);
      });
    },
  }
);

export default select;
