export function createEvent(name) {
    var event;
    if(typeof(Event) === 'function') {
        event = new Event(name);
    }else{
        event = document.createEvent('Event');
        event.initEvent(name, true, true);
    }

   return event
}
