import createBehavior from '../functions/createBehavior';

const videoControls = createBehavior(
  'videoControls',
  {
    changeButtonType(btn, value) {
      const icon = btn.querySelector('svg');
      const text = btn.querySelector('span');
      icon.innerHTML = `<svg class="icon icon--${value}"><use xlink:href="#icon--${value}"></use></svg>`;
      text.innerHTML = value[0].toUpperCase() + value.slice(1);
    },
    playPauseVideo() {
      if (this.video.paused || this.video.ended) {
        // Change the button to a pause button
        this.changeButtonType(this.playPause, 'pause');
        this.video.play();
      } else {
        // Change the button to a play button
        this.changeButtonType(this.playPause, 'play');
        this.video.pause();
      }
    },
    setSource() {
      const sources = this.video.querySelectorAll('source');

      sources.forEach((source) => {
        const desktop = source.getAttribute('data-desktop-src');
        const mobile = source.getAttribute('data-mobile-src');
        if (window.screen.width < 544) {
          source.setAttribute('src', mobile);
        } else {
          source.setAttribute('src', desktop);
        }
      });

      this.video.load();
      this.video.onplaying = () => { if (!this.video.classList.contains('s-playing')) { this.video.classList.add('s-playing'); } };
    }
  },
  {
    init() {
      this.video = this.getChild('video');
      this.playPause = this.getChild('toggle');
      this.setSource();

      this.playPause.addEventListener('click', () => {
        this.playPauseVideo();
      });

      this.video.addEventListener('play', () => {
        this.changeButtonType(this.playPause, 'pause');
      });

      this.video.addEventListener('pause', () => {
        this.changeButtonType(this.playPause, 'play');
      });

      this.video.addEventListener('ended', () => {
        this.video.pause();
      });

      if (!this.video.paused) {
        this.video.classList.add('s-playing');
      }
    },
    mediaQueryUpdated() {
      this.setSource();
    },
    destroy() {
      window.removeEventListener('scroll', this.pageScroll);
    },
  }
);

export default videoControls;
