import createBehavior from '../functions/createBehavior';
import { createEvent } from '../functions/createEvent';

import('smoothscroll-polyfill').then((smoothscroll) => {
    // kick off the polyfill!
    smoothscroll.default.polyfill();
})

const TIMEOUT_SCROLL_ONLOAD = 3000;
const SCROLL_MARGIN_TOP = 173;
const ANCHOR_LINKS_HEIGHT = 73;

const stickyHeader = createBehavior(
  'anchorLinks',
  {
    handleScroll() {
        if (window.pageYOffset >= this.offsetTop) {
            if (this.stickyActive) return;
            window.dispatchEvent(createEvent('freezeStickyHeader'));
            if (!this.hasMediaInHero && this.firstSecUnderAncLnks) {
                this.firstSecUnderAncLnks.style.marginTop = `calc(${this.firstSecUnderAncLnksMarginTop} + ${ANCHOR_LINKS_HEIGHT}px - 48px)`;
            }
            this.dE.classList.add(this.stickyClass);
            this.stickyActive = true;
        } else {
            this.stickyActive = false;
            this.firstSecUnderAncLnks.style.removeProperty('margin-top');
            window.dispatchEvent(createEvent('releaseStickyHeader'))
            this.dE.classList.remove(this.stickyClass);
        }
    },
    handleResize() {
        this.offsetTop = this.node.offsetTop;
        this.getFirstSecUnderAnchLinks();
        this.recalculateDimensions();
    },
    resetActiveLink() {
        this.links.forEach(link => link.parentElement.classList.remove(this.activeClass));
    },
    recalculateDimensions() {
        this.anchorBlocks.forEach((anchorBlock) => {
            let linkId = anchorBlock.getAttribute('id');
            this.linksHashDim[linkId] = anchorBlock.offsetTop - SCROLL_MARGIN_TOP;
        })
    },
    extractLinkId(link) {
        return link.getAttribute('data-anchorLinks-target').split('#').join('');
    },
    scrollOnLoad() {
        setTimeout(() => {
            // fix accuracy to anchorblocks dimension
            this.recalculateDimensions();
            if (this.activeAnchorBlock && this.linksHashDim[this.activeAnchorBlock]) {
                window.scrollTo({
                    top: this.linksHashDim[this.activeAnchorBlock],
                    behavior: 'smooth'
                })
            }
        }, TIMEOUT_SCROLL_ONLOAD);
    },
    cacheLinkHashAndListenForClks() {
        this.links.forEach((link) => {
            let linkId = this.extractLinkId(link);
            this.linksHash[linkId] = link;
            link.addEventListener('click', this.handleClick);
        })
    },
    setLinkActive(targetId) {
        this.linksHash[targetId] && this.linksHash[targetId].parentElement.classList.add(this.activeClass);
    },
    observeAnchorBlocks() {
        this.observer = new IntersectionObserver((entries) => {
            entries
                .forEach(entry => {
                    if (entry.isIntersecting) {
                        const targetId = entry.target.getAttribute('id');
                        const activeLink = this.linksHash[targetId];
                        const activeClassSet = activeLink && activeLink.parentElement.classList.contains(this.activeClass);

                        if (activeClassSet || (this.activeRatio > entry.intersectionRatio && activeLink != this.activeLink)) return
                        this.resetActiveLink()
                        this.setLinkActive(targetId);
                        this.activeRatio = entry.intersectionRatio;
                        this.activeAnchorBlock = entry.target;
                    } else if (entry.target == this.activeAnchorBlock) {
                        this.resetActiveLink();
                        this.activeAnchorBlock = null;
                        this.activeLink = null;
                        this.activeRatio = null;
                    }
            });
          }, {
            threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
        });

        this.anchorBlocks.forEach(anchorBlock => this.observer.observe(anchorBlock))
    },
    getFirstSecUnderAnchLinks() {
        this.firstSecUnderAncLnks = document.querySelector('.o-section');
        this.firstSecUnderAncLnks.style.removeProperty('margin-top');
        if (this.firstSecUnderAncLnks) {
          this.firstSecUnderAncLnksMarginTop = getComputedStyle(document.querySelector('.o-section')).marginTop;
        }
    },
    handleClick(event) {
        event.preventDefault()
        const link = event.target;
        const targetId = this.extractLinkId(link);
        const target = document.querySelector(`#${targetId}`);
        this.activeLink = link;
        // if scroll not supported don't rely on browser scrolling to hash
        if (target) {
            window.location.hash = targetId;
            window.scrollTo({
                top: this.linksHashDim[targetId],
                behavior: 'smooth'
            })
        }
    }
  },
  {
    init() {
      this.dE = document.documentElement;
      this.offsetTop = this.node.offsetTop;
      this.stickyClass = 's-anchorlinks-is-sticky';
      this.activeClass = 'g-nav__item--active';
      this.scrollSupported = window.CSS && window.CSS.supports('scroll-behavior', 'smooth');
      this.linksHash = {};
      this.linksHashDim = {};

      this.links = this.getChildren('link');
      this.cacheLinkHashAndListenForClks();
      this.anchorBlocks = document.querySelectorAll('.o-section--blockAnchor');
      this.hasMediaInHero = !!document.querySelector('.m-hero .a-media');
      this.getFirstSecUnderAnchLinks();
      this.anchorBlocks = Array.from(this.anchorBlocks).filter(blk => Object.keys(this.linksHash).includes(blk.getAttribute('id')));
      this.activeAnchorBlock = window.location.hash.substr(1);

      this.recalculateDimensions();
      this.observeAnchorBlocks()
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
      this.scrollOnLoad();
    },
    destroy() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize);
      this.links.forEach((link) => link.removeEventListener('click', this.handleClick));
      this.anchorBlocks.forEach(anchorBlock => this.observer.unobserve(anchorBlock))
    },
  }
);

export default stickyHeader;
