import './polyfills/polyfills.js';
import 'babel-polyfill';
import resized from './functions/resized.js';
import fontLoadObserver from './functions/fontLoadObserver';
import lazyload from '@area17/a17-lazyload';
import * as Behaviors from './behaviors';
import { manageBehaviors } from './functions/manageBehaviors';
import { intersections } from './functions/intersections';

/*

  A17

  Doc: // Doc: https://code.area17.com/a17/fe-boilerplate/wikis/js-app

*/

// HTML4 browser?
if (!A17.browserSpec || A17.browserSpec === 'html4') {
  // lets kill further JS execution of A17 js here
  throw new Error('HTML4');
}

document.addEventListener('DOMContentLoaded', function() {
  // go go go
  manageBehaviors(Behaviors);

  // on resize, check
  resized();

  // look for intersections
  intersections();

  // lazy load images
  lazyload({
    pageUpdatedEventName: 'page:loaded',
    threshold: 0.01,
    maxFrameCount: 20,
  });

  // var details = document.querySelectorAll('details');
  // Array.from(details).forEach(function(obj, idx) {
  //   obj.open = true;
  // });

  // watch for fonts loading
  fontLoadObserver({
    name: 'brown',
    variants: [
      {
        name: 'Brown',
        weight: '300',
      },
      {
        name: 'Brown',
        weight: '400',
      },
      {
        name: 'Brown',
        weight: '500',
      },
    ],
  });
  fontLoadObserver({
    name: 'untitled',
    variants: [
      {
        name: 'Untitled Sans',
        weight: '400',
      },
      {
        name: 'Untitled Sans',
        weight: '500',
      },
    ],
  });
});
