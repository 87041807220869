import createBehavior from '../functions/createBehavior';

const cookiePreferences = createBehavior(
  'cookiePreferences',
  {
    handleBtnClick(e) {
        e.preventDefault();
        if(typeof Osano === 'undefined') return false;
        Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    },
  },
  {
    init() {
      this.node.addEventListener('click', this.handleBtnClick);
    },
    destroy() {
        this.node.removeEventListener('click', this.handleBtnClick);
    },
  },

);

export default cookiePreferences;
