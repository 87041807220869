var getCurrentMediaQuery = function() {
    const breakpoints = {
        xsmall:  {start: null, end: 543},
        small:   {start: 544,  end: 767},
        medium:  {start: 768,  end: 1023},
        large:   {start: 1024, end: 1279},
        xlarge:  {start: 1280, end: 1519},
        xxlarge: {start: 1520, end: 1999},
        xxxlarge: {start: 2000, end: null}
    }

    let width = window.innerWidth

    for (let key of Object.keys(breakpoints)) {
        let breakpoint = breakpoints[key]

        if (breakpoint.start < width && breakpoint.end > width) {
            return key
        }
    }
};


export default getCurrentMediaQuery;
